<template>
  <div
    class="htmleaf-container"
    :style="{ marginTop: isWxApplets ? '46px' : '' }"
  >
    <template v-if="isWxApplets">
      <van-nav-bar
        title="作业详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <van-cell title="标题" :value="artObj.Title" />
    <van-cell title="课程" :value="artObj.CourseName" />
    <van-cell title="发布时间" :value="artObj.CreateTime" />
    <van-cell title="截止时间" :value="artObj.FinishTime" />
    <van-cell title="发布人" :value="artObj.CreateUserName" />
    <van-cell title="状态">
      <template #extra>
        <span
          :class="[
            artObj.StudentWorkStatus == 0
              ? 'c_3A78F9'
              : artObj.StudentWorkStatus == 1
              ? 'c_D4D2D2'
              : 'c_FE4A4A',
          ]"
          >{{
            artObj.StudentWorkStatus == 0
              ? "未批阅"
              : artObj.StudentWorkStatus == 1
              ? "已批阅"
              : "未提交"
          }}</span
        >
      </template>
    </van-cell>
    <van-panel title="作业描述">
      <div
        v-html="artObj.Content"
        class="htmlStr"
        style="margin: 0px 20px; padding-bottom: 20px"
      ></div>
    </van-panel>
    <div style="height: 10px; background-color: #f5f5f5"></div>
    <van-panel v-if="artObj.StudentWorkStatus != 0">
      <div
        v-html="results != null ? results.Comment : ''"
        class="htmlStr"
        @click="handlePreview($event)"
        style="margin: 0px 20px; padding-bottom: 20px"
      ></div>
      <template #header>
        <div class="hf">
          <div><span>复</span> 老师回复</div>
        </div>
      </template>
    </van-panel>
    <div style="height: 10px; background-color: #f5f5f5"></div>
    <van-panel :status="results != null ? results.StudentFinishTime : ''">
      <!-- <div v-html="results.Comment" style="margin: 0px 20px;"></div> -->
      <div class="editor">
        <!-- <div ref="toolbar" class="toolbar"></div>
        <div ref="editor" class="text"></div> -->
        <div class="edit_container">
          <template>
            <quill-editor
              v-model="results.StudentContent"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            >
            </quill-editor>
            <el-upload
              v-show="false"
              class="upload-demo uploadFile"
              action="str"
              :http-request="postImagesUploadPic"
            >
              <el-button size="small" type="primary">上传文件</el-button>
            </el-upload>
          </template>
        </div>
      </div>
      <template #header>
        <div class="zd">
          <div><span>答</span> 我的作答</div>
          <div class="right">{{ results.StudentFinishTime }}</div>
        </div>
      </template>
    </van-panel>
    <div class="sub_btn">
      <el-button type="primary" @click="saveStudentTask">提交</el-button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ImagePreview } from "vant";
import {
  studentSaveStudentCourseWork,
  studentQueryStudentWorkDetail,
} from "@/api/personal";
import { imagesUploadPic } from "@/api/api";
import { quillEditor, Quill } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import E from "wangeditor";
let xhr;

// 自定义插入a链接
var Link = Quill.import("formats/link");
class FileBlot extends Link {
  // 继承Link Blot
  static create(value) {
    let node = undefined;
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value);
    } else {
      // 自定义Link Blot
      node = super.create(value.href);
      // node.setAttribute('download', value.innerText);  // 左键点击即下载
      node.innerText = value.innerText;
      node.download = value.innerText;
    }
    return node;
  }
}
FileBlot.blotName = "link";
FileBlot.tagName = "A";
Quill.register(FileBlot);
// 编辑器工具栏配置
const toolbarOptions = [
  // ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  // ['blockquote', 'code-block'],     //引用，代码块
  // [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  // [{ list: "ordered" }, { list: "bullet" }], //列表
  // [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
  // [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ 'direction': 'rtl' }],             // 文本方向
  // [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
  // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
  // [{ 'font': [] }],     //字体
  [{ align: [] }], //对齐方式
  ["link", "image"], //, "upload"  //上传图片、上传视频
  ["clean"], //清除字体样式
];
let self = this;
let state = 0;
export default {
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  data() {
    return {
      artObj: {},
      imgsrc: "",
      info_: null,
      results: {
        StudentContent: "55",
      },
      studentId: 0,
      isWxApplets: true,
      editorOption: {
        placeholder: "请在这里输入您的发帖内容",
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: (value) => {
                //编辑器-上传图片
                if (value) {
                  state = 1;
                  //禁止软键盘弹出
                  document.activeElement.blur();
                  document.querySelector(".uploadFile input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              // upload: (value) => {
              //   //编辑器-上传文件
              //   if (value) {
              //     state = 2;
              //     document.querySelector(".uploadFile input").click();
              //   }
              // },
            },
          },
        },
      },
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "作业详情";
      this.isWxApplets = false;
    }
    this.artObj = JSON.parse(localStorage.getItem("studentTaskObj"));
    // console.log(this.artObj);
  },
  mounted() {
    this.studentId =
      this.$route.query.userId > 0 ? this.$route.query.userId : 0;
    // this.seteditor();
    this.init();
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    handlePreview(e) {
      // let array = this.common.getImgSrc(this.results.StudentContent)
      ImagePreview({
        images: [e.target.currentSrc],
        closeable: true,
      });
    },
    // 上传文件
    async postImagesUploadPic(param) {
      let newFile = await this.common.compressImg(param.file);
      const formData = new FormData();
      formData.append("file", newFile);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let quill = this.$refs.myQuillEditor.quill;
        let length = quill.getSelection().index;
        // console.log(state, "");
        if (state == 1) {
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(
            length,
            "image",
            this.GLOBAL.hostUrl + res.response
          );
        } else if (state == 2) {
          // 插入文件，res为服务器返回的文件链接地址
          quill.insertEmbed(length, "link", {
            href: this.GLOBAL.hostUrl + res.response,
            innerText: param.file.name,
          });
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        // console.log(res.response, '')
        // console.log(this.content, "");
      } else {
        this.$message.error(res.msg);
      }
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {
      console.log(this.content, "");
    },
    // 内容改变事件
    async init() {
      let res = await studentQueryStudentWorkDetail(
        this.artObj.Id + "&studentId=" + this.studentId
      );
      if (res.success === true) {
        this.results = res.response ? res.response : {};
        if (res.response === null) {
          this.results.Comment = "";
          this.results.StudentContent = "";
          this.results.StudentFinishTime = "";
        }
        // console.log(this.results, "")
        // if (this.results != null) {
        //   // this.editor.txt.html(this.results.StudentContent);
        //   this.info_ = this.results.StudentContent;
        // }
      } else {
        this.$message.error(res.msg);
      }
    },
    goBack() {
      this.$router.push({
        path: "/h5/studentTask/index",
        query: { UserId: this.artObj.StudentId },
      });
    },
    photoCompress(file, w, objDiv) {
      let that = this;
      var ready = new FileReader();
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file);
      ready.onload = function () {
        var re = this.result;
        that.canvasDataURL(re, w, objDiv);
      };
    },
    canvasDataURL(path, obj, callback) {
      var img = new Image();
      img.src = path;
      img.onload = function () {
        var that = this;
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        var quality = 0.7; // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        // 创建属性节点
        var anw = document.createAttribute("width");
        anw.nodeValue = w;
        var anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality;
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL("image/jpeg", quality);
        // 回调函数返回base64的值
        callback(base64);
      };
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    seteditor() {
      let that = this;
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.customConfig.uploadImgServer =
        this.GLOBAL.hostUrl + "images/Upload/Pic"; // 配置服务器端地址
      this.editor.customConfig.customUploadImg = function (files, insert) {
        var fileObj = files[0];
        // files 是 input 中选中的文件列表
        let formData = new FormData();
        if (fileObj.size / 1024 > 1025) {
          that.photoCompress(
            fileObj,
            {
              quality: 0.2,
            },
            function (base64Codes) {
              // console.log(
              //   "压缩后：" + base64Codes.length / 1024 + " " + base64Codes
              // );
              var bl = that.convertBase64UrlToBlob(base64Codes);
              formData.append(
                "file",
                bl,
                "file_" + Date.parse(new Date()) + ".jpg"
              ); // 文件对象
            }
          );
        } else {
          formData.append("file", files[0]);
        }
        setTimeout(() => {
          that
            .axios({
              //上传图片到后台
              method: "post",
              url: that.GLOBAL.hostUrl + "images/Upload/Pic",
              data: formData,
            })
            .then((res) => {
              if (res.data.success) {
                let imgUrl = that.GLOBAL.hostUrl + res.data.response;
                insert(imgUrl); //生成img标签并插入文章中
              } else {
                that.$message.error(res.data.msg);
              }
            });
        }, 100);
        // insert 是获取图片 url 后，插入到编辑器的方法

        // 上传代码返回结果之后，将图片插入到编辑器中
      };
      // 配置菜单
      this.editor.customConfig.menus = [
        "head", // 标题
        "link",
        // "fontSize", // 字号
        // "foreColor", // 文字颜色
        // "backColor", // 背景颜色
        "justify", // 对齐方式
        // "emoticon", // 表情
        "image", // 插入图片
      ];

      // this.editor.customConfig.uploadImgHooks = {
      //   fail: (xhr, editor, result) => {
      //     // 插入图片失败回调
      //   },
      //   success: (xhr, editor, result) => {
      //     // 图片上传成功回调
      //   },
      //   timeout: (xhr, editor) => {
      //     // 网络超时的回调
      //   },
      //   error: (xhr, editor) => {
      //     // 图片上传错误的回调
      //   },
      //   customInsert: (insertImg, result, editor) => {
      //     // 图片上传成功，插入图片的回调
      //     //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
      //     //这块一定要细心，因为会影响到上传图片成功之后的回显
      //     console.log(result);
      //     let url = this.GLOBAL.hostUrl + result.response;
      //     insertImg(url);
      //     // }
      //   },
      // };

      this.editor.customConfig.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        // this.$emit("change", this.info_); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
    },
    async saveStudentTask() {
      if (this.results.StudentContent) {
        let parm = {
          workId: this.artObj.Id,
          courseId: this.artObj.CourseId,
          content: this.results.StudentContent,
          StudentId: this.artObj.StudentId,
        };
        console.log(parm, "");
        // return
        const res = await studentSaveStudentCourseWork(parm);
        if (res.success == true) {
          this.$message.success(res.msg);
          this.goBack();
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$message.info("请输入您的作答信息");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.htmleaf-container {
}
.van-cell {
  font-size: 15px;
  border-bottom: 1px solid #f5f5f5;
  .van-cell__title {
    color: #666666;
    font-weight: bold;
  }
  .van-cell__value {
    flex: 0 0 75%;
    color: #333333;
  }
}
.van-panel {
  .hf,
  .zd {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 47px;
    margin: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    span {
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 20px;
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      display: inline-block;
      margin-right: 5px;
    }
    .right {
      font-size: 12px;
      color: #999999;
      font-weight: normal;
    }
  }
  .hf {
    span {
      border: 1px solid #fe4a4a;
      color: #fe4a4a;
    }
  }
  .zd {
    span {
      border: 1px solid #3a78f9;
      color: #3a78f9;
    }
  }
}
.sub_btn {
  margin: 20px auto;
  text-align: center;
  button {
    width: 280px;
    font-size: 15px;
    height: 42px;
    // background: #4481fe;
    background: #333;
    box-shadow: 0px 4px 6px rgba(22, 121, 253, 0.22);
    border-radius: 30px;
  }
}
.htmlStr {
  margin: 0px 20px;
  /deep/ img {
    max-width: 100%;
  }
}
img {
  max-width: 100%;
}
.text {
  min-height: 200px;
}

/deep/.ql-editor {
  min-height: 100px !important;
}
/deep/.quill-editor {
  .ql-toolbar {
    .ql-formats {
      margin-right: 0;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
